import React from 'react';
import { useThemeContext } from '../components/ThemeContextProvider';
import {
  DarkModeLoginPMLogoIcon,
  LightModeLoginPMLogoIcon,
} from './LoginPMLogoIcons';

const LoginPMLogo = () => {
  const { theme } = useThemeContext();
  return theme === 'dark' ? (
    <DarkModeLoginPMLogoIcon />
  ) : (
    <LightModeLoginPMLogoIcon />
  );
};

export default LoginPMLogo;
